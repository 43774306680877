<template>
  <div>
    <sdPageHeader title="Script">
    </sdPageHeader>
    <Main v-if="errorScript">
      <sdCards title="">
        <a-result status="warning" title="Er is een fout opgetreden bij het ophalen van de gegevens."
                  style="padding: 0">
          <template #extra>
            <Button class="ant-btn ant-btn-primary" type="primary" size="large" @click="$router.replace('/script')">
              Naar scripts
            </Button>
          </template>
        </a-result>
      </sdCards>
    </Main>
    <Main v-if="!errorScript">
      <div class="user-info-form">
        <BasicFormWrapper class="mb-25">
          <sdCards title="Algemeen">
            <a-form :model="form" :rules="rules" layout="vertical" @finish="UpdateScript">
              <a-row :gutter="30">
                <a-col :sm="12" :xs="24" class="mb-25">
                  <a-form-item label="Naam" name="name">
                    <a-input :disabled="!$can('update', 'script')" v-model:value="form.name" placeholder=""/>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row :gutter="30" class="mb-25">
                <a-col :sm="24" :xs="24" class="mb-25">
                  <a-form-item label="Script">
                    <div class="sDash_editor">
                      <editor
                          v-model="form.text"
                          :disabled="!$can('update', 'script')"
                          api-key="rn8dtc2ppc7lm2i4zfsm3vqm8dnskc4p03cpnknqk6xjnelc"
                          :init="{
                             height: 500,
                             menubar: false,
                             plugins: [
                               'advlist autolink lists link image charmap print preview anchor template',
                               'searchreplace visualblocks code fullscreen',
                               'insertdatetime media table paste code help wordcount'
                             ],
                             toolbar:
                               'undo redo | formatselect | bold italic backcolor | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlist outdent indent | removeformat | template | help',
                            templates: [
                              {title: 'Some title 1', description: 'Some desc 1', content: 'My content'},
                              {title: 'Some title 2', description: 'Some desc 2', url: 'development.html'}
                            ]
                           }"
                      />
                    </div>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col :sm="12" :xs="24">
                  <div class="sDash_form-action">
                    <sdButton v-if="$can('update', 'script')" class="btn-signin" type="primary"> Opslaan</sdButton>
                  </div>
                </a-col>
              </a-row>
            </a-form>
          </sdCards>
        </BasicFormWrapper>
      </div>
      <sdCards>
        <a-row>
          <a-col :sm="12" :xs="24">
            <div class="sDash_form-action">
              <sdButton class="btn-signin" html-type="submit" type="light" @click="$router.replace('/script')">
                Terug
              </sdButton>
            </div>
          </a-col>
        </a-row>
      </sdCards>
    </Main>
  </div>
</template>

<script>
import {BasicFormWrapper, Main} from '../styled';
import {computed, defineComponent, onMounted, reactive} from 'vue';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import Editor from '@tinymce/tinymce-vue'

export default defineComponent({
  name: 'ScriptEdit',
  components: {
    BasicFormWrapper,
    Main,
    'editor': Editor,
  },
  setup() {
    const {state, dispatch} = useStore();
    const {params} = useRoute();
    const form = reactive(computed(() => state.script.script));
    const errorScript = computed(() => state.script.errorScript);
    const rules = {
      name: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
      text: [
        {
          required: true,
          message: 'Please enter user name',
        },
      ],
    };
    const UpdateScript = () => {
      dispatch('patchScript', {
        id: params.id,
        name: form.value.name,
        text: form.value.text,
      });
    };

    onMounted(() => {
      dispatch('getScript', params.id);
    });

    return {
      form,
      rules,
      errorScript,
      UpdateScript,
    };
  },
});
</script>
